body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #111;
  line-height: 1.5;
  font-weight: 400;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif;
}

img {
  width: 100%;
  height: auto;
}

span, a, a:hover {
  display: inline-block;
  color: inherit;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 25px;
}

h6 {
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.4;
  font-weight: bold;
}

p {
  color: #888;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  margin: 0;
}

a, span {
  display: inline-block;
}

a:hover, span:hover {
  text-decoration: none;
}

.container {
  max-width: 1200px;
}

.custom-font {
  font-family: 'Poppins', sans-serif;
}

.color-font {
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.back-color {
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 

}

.line {
  position: absolute;
  height: 1px;
  width: 65%;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
}

.line.top {
  top: 0;
}

.line.bottom {
  bottom: 0;
}

.line.left {
  left: 0;
}

.line.right {
  right: 0;
}

/* ====================== [ End Typography  ] ====================== */
/* ====================== [ Start Helper Classes  ] ====================== */
.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.ontop {
  position: relative;
  z-index: 7;
}

.full-over {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.full-width {
  width: 100% !important;
}

.main-color {
  color: #12c2e9 !important;
}

.bg-gray {
  background-color: #f8f4f3;
}

.bg-gray .primery-shadow {
  -webkit-box-shadow: 0px 4px 30px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 30px -4px rgba(0, 0, 0, 0.2);
}

.bg-dark {
  background-color: #141414 !important;
}

.bg-dark .primery-shadow {
  -webkit-box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
}

.bg-color {
  background-color: #12c2e9 !important;
}

.bg-color .primery-shadow {
  -webkit-box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 40px -4px rgba(255, 255, 255, 0.2);
}

.sub-bg {
  background: #f5f5f5;
  
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center center;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}

.section-padding {
  padding: 120px 0;
}

.section-ptb {
  padding: 80px 0;
}

.cmd-padding {
  padding: 0 10px;
}

.csm-padding {
  padding: 0 5px;
}

.head-pt {
  padding: 80px 0 0;
}

.head-pb {
  padding: 0 0 80px;
}

.rounded {
  border-radius: 5px;
}

.circle {
  border-radius: 50%;
}

.unlist {
  list-style: none;
}

.undecro:hover {
  text-decoration: none;
}

.inline {
  display: inline-block;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.primery-shadow {
  -webkit-box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
}

.padding5 {
  padding-right: 5%;
  padding-left: 5%;
}

/*-------------------------------------------------------
					 text color
-------------------------------------------------------*/
.text-white {
  color: #FFF;
}

.text-black {
  color: #000;
}

.text-extra-light-gray {
  color: #b7b7b7;
}

/*-------------------------------------------------------
					font-weight
-------------------------------------------------------*/
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/*-------------------------------------------------------
					 font-size
-------------------------------------------------------*/
.fz-10 {
  font-size: 10px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-15 {
  font-size: 15px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-25 {
  font-size: 25px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-50 {
  font-size: 50px !important;
}

/*-------------------------------------------------------
					line-height
-------------------------------------------------------*/
.line-height-10 {
  line-height: 10px;
}

.line-height-13 {
  line-height: 13px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-35 {
  line-height: 35px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-45 {
  line-height: 45px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-55 {
  line-height: 55px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-65 {
  line-height: 65px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-75 {
  line-height: 75px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-85 {
  line-height: 85px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-95 {
  line-height: 95px;
}

.line-height-100 {
  line-height: 100px;
}

.line-height-110 {
  line-height: 110px;
}

.line-height-120 {
  line-height: 120px;
}

/*-------------------------------------------------------
					text-transform
-------------------------------------------------------*/
.text-u {
  text-transform: uppercase !important;
}

.text-l {
  text-transform: lowercase !important;
}

.text-c {
  text-transform: capitalize !important;
}

.text-non {
  text-transform: none !important;
}

.text-i {
  font-style: italic;
}

/*-------------------------------------------------------
					 letter-spacing
-------------------------------------------------------*/
.ls0 {
  letter-spacing: 0px !important;
}

.ls1 {
  letter-spacing: 1px !important;
}

.ls2 {
  letter-spacing: 2px !important;
}

.ls3 {
  letter-spacing: 3px !important;
}

.ls4 {
  letter-spacing: 4px !important;
}

.ls5 {
  letter-spacing: 5px !important;
}

.ls10 {
  letter-spacing: 10px !important;
}

/*-------------------------------------------------------
			 margin-top  (0/100) +5
-------------------------------------------------------*/
.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

/*-------------------------------------------------------
			 margin-bottom  (0/100) +5
-------------------------------------------------------*/
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

/*-------------------------------------------------------
			padding-top  (0/100) +5
-------------------------------------------------------*/
.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/*-------------------------------------------------------
		 padding-bottom  (0/100) +5
-------------------------------------------------------*/
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.no-padding {
  padding: 0 !important;
}

/* ====================== [ End Helper Classes  ] ====================== */
/* ====================== [ Start Preloader ] ====================== */
/* .pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99999999999999;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.pace .pace-progress {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 300px;
  position: fixed;
  z-index: 99999999999999;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #12c2e9;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
}

.pace.pace-inactive {
  width: 100vw;
  opacity: 0;
}

.pace.pace-inactive .pace-progress {
  max-width: 100vw;
}

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
}

#preloader:after, #preloader:before {
  content: '';
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: #252531;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

#preloader:before {
  top: 0;
}

#preloader:after {
  bottom: 0;
}

#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

#preloader.isdone:after, #preloader.isdone:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.loading {
  color: #fff;
  position: fixed;
  left: 50%;
  top: calc(50% - 40px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 10px;
  z-index: 9999999999;
}

.loading.isdone {
  top: 50%;
  opacity: 0;
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.loading span {
  -webkit-animation: loading 1.4s infinite alternate;
  animation: loading 1.4s infinite alternate;
}

.loading span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loading span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.loading span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loading span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

/* ====================== [ End Preloader ] ====================== */
/* ====================== [ Start Text Animation & Images ] ====================== */
.splitting.animated .char {
  -webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.splitting .whitespace {
  width: 5px;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.splitting.txt.animated .char {
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(10ms * var(--char-index));
  animation-delay: calc(10ms * var(--char-index));
}

.splitting.txt .whitespace {
  width: 5px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imago {
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); */
  /* clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); */
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.imago.animated {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* ====================== [ End Text Animation & Images ] ====================== */
/* ====================== [ Start Cursor Style ] ====================== */


.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  margin-left: 2px;
  margin-top: 2px;
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: #12c2e9;
  -webkit-transition: width .3s ease-in-out,
 height .3s ease-in-out,
 margin .3s ease-in-out,
 opacity .3s ease-in-out;
  -o-transition: width .3s ease-in-out,
 height .3s ease-in-out,
 margin .3s ease-in-out,
 opacity .3s ease-in-out;
  transition: width .3s ease-in-out,
 height .3s ease-in-out,
 margin .3s ease-in-out,
 opacity .3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: #12c2e9;
  opacity: .3;
}

.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 40px;
  height: 40px;
  border: 1px solid #12c2e9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: .5;
  -webkit-transition: all .08s ease-out;
  -o-transition: all .08s ease-out;
  transition: all .08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

/* ====================== [ End Cursor Style ] ====================== */
/* ====================== [ Start progress-wrap ] ====================== */
.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  content: '\f077';
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  color: #12c2e9;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #12c2e9;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

/* ====================== [ End progress-wrap ] ====================== */
/* ====================== [ Start animate headline ] ====================== */
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* xclip */
.cd-headline.clip span {
  display: inline-block;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline b.is-visible {
  opacity: 1;
}

/* ====================== [ End animate headline ] ====================== */
/* ====================== [ Start Overlay ] ====================== */
[data-overlay-dark],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-light] .container {
  position: relative;
  z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay-dark]:before {
  background: #121319;
}

[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
  color: #fff;
}

[data-overlay-dark] p,
.bg-dark p,
.bg-color p {
  color: #dad6d6;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: .1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: .2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: .3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: .4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: .5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: .6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: .7;
}

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: .8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
  opacity: .9;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}

/* ====================== [ End Overlay ] ====================== */
/* ====================== [ Start Heading ] ====================== */
.sec-head {
  position: relative;
  margin: 0 auto 80px;
}

.sec-head h6 {
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 7px;
  margin-bottom: 10px;
}

.sec-head h3 {
  font-size: 48px;
  font-weight: 600;
}

.sec-head h2 {
  font-size: 70px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sub-title {
  position: relative;
  margin-bottom: 20px;
}

.sub-title h6 {
  font-weight: 300;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  padding: 7px 12px;
  border-radius: 10px;
}

.main-title {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 15px;
}

.extra-title {
  font-weight: 700;
}

.sm-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.smore {
  position: relative;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 7px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
}

.smore:after {
  content: '';
  width: 45%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  position: absolute;
  left: 0;
  top: 0;
}

.smore i {
  margin-left: 10px;
}

.line-v {
  position: absolute;
  height: 80px;
  width: 1px;
  background: #c5a47e;
  left: 50%;
  opacity: .3;
}

.line-v.top {
  top: -30px;
}

.line-v.bottom {
  bottom: -30px;
}

/* ====================== [ End Heading ] ====================== */
/* ====================== [  Start Button Style ] ====================== */
.butn {
  padding: 15px 35px;
  font-size: 13px;
  border: 1px solid transparent;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.butn:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.butn.bord {
  border: 1px solid #000;
}

.butn.bord:hover {
  background: #111;
  color: #fff;
}

.butn.light {
  background: #fff;
  color: #333;
}

.butn.light:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.butn.dark {
  background: #222;
  color: #fff;
}

.butn.dark:hover {
  background: transparent;
  border-color: #222;
  color: #333;
}

.butn.color {
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  border: transparent;
}

.butn.color:hover {
  background: #fff;
  color: #111;
}

.butn.curve {
  border-radius: 5px;
}

.butn.radius {
  border-radius: 30px;
}

[data-overlay-dark] .butn.bord {
  border-color: #fff;
}

[data-overlay-dark] .butn.bord:hover {
  background: #fff;
}

[data-overlay-dark] .butn.bord:hover span {
  color: #111;
}

.simple-btn {
  position: relative;
  padding: 7px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 13px;
  font-weight: 300;
}

.simple-btn:after {
  content: '';
  width: 45%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
}

.simple-btn.right:after {
  left: auto;
  right: 0;
}

/* ====================== [ End Button Style ] ====================== */
/* ====================== [ Start navbar ] ====================== */
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid transparent;
  z-index: 999;
  min-height: 80px;
}

.navbar .icon-bar {
  color: #111;
}

.navbar.light .navbar-nav .nav-link {
  color: #111;
}

.navbar .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 15px 6px;
  margin: 10px 10px;
}
.dropdown:hover .dropdown-menu{
  display: block;
}

.navbar .navbar-nav .dropdown-menu {
  padding: 10px 0;
  border: 0;
  -webkit-box-shadow: 0px 10px 40px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 40px -4px rgba(0, 0, 0, 0.1);
}

.navbar .navbar-nav .dropdown-item {
  font-size: 13px;
  padding: 10px 20px;
  opacity: .9;
}
.navbar .container-fluid{
  width: 95%;
}
.navbar .navbar-nav .dropdown-item:hover {
  background: transparent;
  color: #12c2e9;
}

.navbar .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  color: #111;
  background: #f5f5f5;
  min-width: 185px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.navbar .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.navbar .dropdown-menu .dropdown-item {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  padding: 10px 25px;
  position: relative;
}

.navbar .dropdown-menu .dropdown-item:after {
  content: '';
  width: 0px;
  height: 1px;
  background: #12c2e9;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.navbar .dropdown-menu .dropdown-item:hover {
  padding-left: 30px;
}

.navbar .dropdown-menu .dropdown-item:hover:after {
  width: 10px;
}

.navbar .search {
  color: #fff;
  padding-left: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar .search .icon {
  cursor: pointer;
}

.navbar .search .search-form {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(37, 37, 49, 0.98);
  display: none;
}

.navbar .search .search-form form {
  width: 600px;
  position: absolute;
  left: calc(50% - 300px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar .search .search-form form input {
  padding: 15px 0;
  font-size: 80px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #12c2e9;
}

.navbar .search .search-form .close {
  position: absolute;
  top: 20%;
  right: 20%;
  color: #fff;
  font-size: 60px;
  opacity: 1;
  cursor: pointer;
}

.navbar .logo {
  width: 220px;
}

.nav-scroll {
  background: #fff;
  border-color: #f5f5f5;
  padding: 0;
  position: fixed;
  top: -80px;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.nav-scroll .navbar-nav .nav-link {
  color: #111;
}

.topnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  padding: 30px 0;
  color: #fff;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.topnav.light {
  color: #111;
}

.topnav.light .text:after {
  color: #111;
}

.topnav.light .icon i {
  background: #111 !important;
}

.topnav .container,
.topnav .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topnav .container-fluid {
  padding: 0 50px;
}

.topnav .logo {
  width: 50px;
}

.topnav .menu-icon {
  margin-left: auto;
  cursor: pointer;
}

.topnav .menu-icon:hover .text .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.topnav .menu-icon .text {
  font-size: 13px;
  font-weight: 500;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.topnav .menu-icon .text:after {
  content: 'close';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 15px;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.topnav .menu-icon .text .word {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.topnav .menu-icon .text.open .word {
  opacity: 0;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.topnav .menu-icon .text.open:after {
  opacity: 1;
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.topnav .menu-icon .icon {
  display: table-cell;
}

.topnav .menu-icon .icon i {
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  margin: 4px 0;
}

.hamenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #eee;
  padding: 120px 30px 30px;
  overflow: hidden;
  z-index: 9999;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
}

.hamenu.open .menu-links .main-menu > li .link {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .cont-info:after {
  height: 100vh;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .cont-info .item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu .menu-links {
  margin-top: 30px;
}

.hamenu .menu-links .o-hidden {
  display: inline-block;
}

.hamenu .menu-links .main-menu {
  position: relative;
  z-index: 2;
}

.hamenu .menu-links .main-menu.gosub > li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: .2s !important;
  -o-transition-delay: .2s !important;
  transition-delay: .2s !important;
}

.hamenu .menu-links .main-menu.gosub > li .link {
  -webkit-transform: translateY(45px) !important;
  -ms-transform: translateY(45px) !important;
  transform: translateY(45px) !important;
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition-delay: .2s !important;
  -o-transition-delay: .2s !important;
  transition-delay: .2s !important;
}

.hamenu .menu-links .main-menu.gosub .sub-menu.sub-open {
  z-index: 3 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.hamenu .menu-links .main-menu > li {
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.hamenu .menu-links .main-menu > li span.nm {
  opacity: .8;
  font-size: 11px;
  margin-right: 10px;
}

.hamenu .menu-links .main-menu > li .link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  cursor: pointer;
}

.hamenu .menu-links .main-menu > li .link i {
  font-size: 15px;
  margin-left: 5px;
}

.hamenu .menu-links .main-menu .sub-menu {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.hamenu .menu-links .main-menu .sub-menu.sub-open {
  z-index: 3;
}

.hamenu .menu-links .main-menu .sub-menu.sub-open li .sub-link {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu .menu-links .main-menu .sub-menu li {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 8px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link.back {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link.back i {
  margin-left: 10px;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link em {
  font-size: 13px;
  font-weight: 300;
  margin-left: 10px;
}

.hamenu .cont-info {
  margin-top: 50px;
  position: relative;
}

.hamenu .cont-info:after {
  content: '';
  width: 1px;
  height: 0;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -40px;
  top: -170px;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .cont-info .item {
  margin-bottom: 30px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  opacity: 0;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .cont-info .item:last-of-type {
  margin-bottom: 0;
}

.hamenu .cont-info .item h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* ====================== [ End navbar ] ====================== */
/* ====================== [ Start Slider ] ====================== */
.slider {
  position: relative;
}

/* .slider.fixed-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
} */
.hero_1{
  background-image: url(../img/banner_img_4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero_2{
  background-image: url(../img/banner_img_5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero_3{
  background-image: url(../img/banner_img_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero_4{
  background-image: url(../img/banner_img_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxie_section{
  background-image: url(../img/bg-vid.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.call_action{
  background-image: url(../img/patrn.svg);
  background-position: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.slider .swiper-slide-active {
  z-index: 3;
}

.slider .parallax-slider {
  position: relative;
}

.slider .parallax-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}

.slider .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.slider .parallax-slider .swiper-slide-active .caption h1[data-splitting] {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider .parallax-slider .swiper-slide-active .caption h1[data-splitting] .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider .parallax-slider .swiper-slide-active .caption .thin,
.slider .parallax-slider .swiper-slide-active .caption .butn,
.slider .parallax-slider .swiper-slide-active .caption h1,
.slider .parallax-slider .swiper-slide-active .caption p {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider .parallax-slider .caption .thin {
  color: #12c2e9;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.slider .parallax-slider .caption .thin span {
  color: #12c2e9;
}

.slider .parallax-slider .caption h1 {
  font-size: 65px;
  font-weight: 800;
  letter-spacing: 2px;
  color: #fff;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  visibility: hidden;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}
.slider .parallax-slider .caption h1.slider_content{
  font-size: 45px !important;
}
.slider .parallax-slider .caption p {
  color: #eee;
  margin-top: 15px;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.slider .parallax-slider .caption .butn {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.slider .parallax-slider .caption.dig h1 {
  font-weight: 800;
  font-size: 80px;
  text-transform: uppercase;
}

.slider .parallax-slider .caption.dig h1 .tline {
  margin-left: 80px;
}

.slider .parallax-slider .caption.dig h1 .whitespace {
  width: 20px;
}

.slider .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}

.slider .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}

.slider .setone .swiper-nav-ctrl:after {
  display: none;
}

.slider .setone.setwo {
  position: static;
}

.slider .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}

.slider .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}

.slider .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}

.slider .txt-botm {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 8;
}

.slider .txt-botm .swiper-nav-ctrl {
  position: absolute;
  width: auto;
  height: auto;
  margin-top: auto;
  top: auto;
  bottom: 0;
}

.slider .txt-botm .swiper-nav-ctrl i {
  color: #888;
  font-size: 13px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.slider .txt-botm .swiper-nav-ctrl span {
  color: #111;
  font-weight: 500;
  font-size: 12px;
}

.slider .txt-botm .swiper-nav-ctrl:after {
  display: none;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-next {
  right: 40px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-next i {
  margin-left: 10px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev {
  left: 40px;
}

.slider .txt-botm .swiper-nav-ctrl.swiper-button-prev i {
  margin-right: 10px;
}

.slider .txt-botm .swiper-nav-ctrl:hover i {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.slider.showcase-full .txt-botm i {
  color: #eee;
}

.slider.showcase-full .txt-botm span {
  color: #fff;
}

.slider.showcase-full .txt-botm:hover i {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.slider .swiper-pagination-fraction.top {
  position: absolute;
  right: 15%;
  left: auto;
  bottom: auto;
  top: 20%;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.slider .swiper-pagination-fraction.top .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: .5px #fff;
}

.slider .swiper-pagination-fraction.top .swiper-pagination-current:after {
  content: '/';
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}

.slider .swiper-pagination-fraction.top.botm {
  top: auto;
  bottom: 40px;
  right: 40px;
}

.slider .swiper-pagination-fraction.steps {
  position: absolute;
  width: 100px;
  color: #fff;
  font-size: 15px;
  bottom: 40px;
  left: calc(50% - 50px);
  right: auto;
  top: auto;
  text-align: left;
}

.slider .swiper-pagination-fraction.steps:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 15%;
  width: 70%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.slider .swiper-pagination-fraction.steps .swiper-pagination-total {
  float: right;
}

.slider .social-icon {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 8;
}

.slider .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 13px;
}

.slider-st {
  min-height: 100vh;
  padding: 120px 0;
}

.slider-st .img {
  position: relative;
}

.slider-st .img:after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  border-radius: 15px;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.slider-st .img:before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  background: #fff;
  border-radius: 15px;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
  opacity: .08;
}

.slider-st .img img {
  border-radius: 15px;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  position: relative;
  z-index: 2;
}

.slider-stwo {
  min-height: 100vh;
  position: relative;
  padding: 120px 0;
}

.slider-stwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background: #f5f5f5;
  z-index: -1;
}

.slider-stwo .img {
  padding-right: 30px;
}

.slider-stwo .img img {
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.05);
  box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.05);
}

.slider-stwo ul {
  margin: 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-stwo ul li {
  display: inline-block;
}

.slider-stwo ul li div {
  display: table-cell;
}

.slider-stwo ul li .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  position: relative;
  font-size: 20px;
}

.slider-stwo ul li .icon .bord {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-stwo ul li .icon .bord:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 5px;
  z-index: -1;
}

.slider-stwo ul li .icon .bord:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  z-index: -2;
}

.slider-stwo ul li .cont {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;
}

.slider-stwo ul li .cont p {
  font-size: 13px;
  margin-top: 5px;
}

.particles {
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

.particles .cont h1 {
  font-size: 90px;
  font-weight: 800;
}

.arch-slider {
  padding-bottom: 80px;
}

.arch-slider .parallax-slider .caption h5 {
  font-size: 50px;
  font-weight: 200;
}

.arch-slider .parallax-slider .caption h1 {
  font-size: 75px;
  font-weight: 600;
  letter-spacing: 2px;
}

.arch-slider .parallax-slider .caption p {
  font-weight: 300;
  padding-left: 100px;
  margin-left: 100px;
  position: relative;
}

.arch-slider .parallax-slider .caption p:after {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  background: #eee;
  width: 70px;
  height: 1px;
}

.arch-slider .parallax-slider .explore {
  margin-left: auto;
  margin-top: -100px;
  color: #fff;
}

.arch-slider .parallax-slider .explore a {
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.arch-slider .parallax-slider .explore a i {
  font-size: 12px;
  margin-left: 10px;
}

.arch-slider .setting {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 50px;
  width: 300px;
  background: #fff;
  z-index: 4;
}

.arch-slider .setting .swiper-button-next,
.arch-slider .setting .swiper-button-prev {
  color: #111;
}

.arch-slider .setting .swiper-button-next:after,
.arch-slider .setting .swiper-button-prev:after {
  display: none;
}

.arch-slider .setting .next-ctrl {
  padding-left: 25px;
  margin-right: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.arch-slider .setting .prev-ctrl {
  padding-right: 25px;
  margin-left: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.arch-slider .setting .swiper-pagination-bullets {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.arch-slider .setting .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  margin: 0 7px;
  position: relative;
  opacity: 1;
}

.arch-slider .setting .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background: #111;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.freelancre {
  min-height: 100vh;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.freelancre:after {
  content: '';
  width: 140%;
  background: rgba(0, 0, 0, 0.04);
  height: 100%;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
  position: absolute;
  top: -40%;
  left: -20%;
  z-index: -1;
}

.freelancre .img {
  position: relative;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  z-index: 2;
}

.freelancre .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.freelancre .img:after {
  content: '';
  background: -webkit-gradient(linear, right top, left top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
  background: -webkit-linear-gradient(right, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(right, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  z-index: -1;
}

.freelancre .cont h1 {
  font-size: 35px;
}

.freelancre .states {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 50px;
}

.freelancre .states .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.freelancre .states ul {
  margin: 0;
}

.freelancre .states li {
  margin-right: 100px;
}

.freelancre .states li:last-of-type {
  margin-right: 0;
}

.freelancre .states li h3 {
  font-size: 50px;
  font-weight: 400;
  margin-right: 20px;
}

.freelancre .states li p {
  font-size: 12px;
  text-transform: uppercase;
}

.freelancre .states .mail-us {
  margin-left: auto;
}

.freelancre .states .mail-us .text {
  text-align: right;
  padding-right: 20px;
}

.freelancre .states .mail-us .text h6 {
  font-size: 14px;
  margin-top: 5px;
}

.freelancre .states .mail-us .icon-box {
  width: 60px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  border-radius: 5px;
  background: -webkit-linear-gradient(323.53deg, #eee 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
  background: -o-linear-gradient(323.53deg, #eee 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
  background: linear-gradient(126.47deg, #eee 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
  font-size: 30px;
}

.slider-bus {
  min-height: 100vh;
  position: relative;
}

.slider-bus .swiper-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-bus .setone {
  position: absolute;
  bottom: 8%;
  right: 40px;
  z-index: 8;
}

.slider-bus .setone .swiper-nav-ctrl {
  color: #ccc;
  font-size: 13px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin: 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: static;
}

.slider-bus .setone .swiper-nav-ctrl:after {
  display: none;
}

.slider-bus .setone.setwo {
  position: static;
}

.slider-bus .setone.setwo .swiper-nav-ctrl {
  border-radius: 50%;
}

.slider-bus .setone.setwo .next-ctrl {
  position: absolute;
  top: 50%;
  right: 30px;
}

.slider-bus .setone.setwo .prev-ctrl {
  position: absolute;
  top: 50%;
  left: 30px;
}

.slider-bus .swiper-pagination-fraction {
  position: absolute;
  right: auto;
  left: 60px;
  bottom: 30px;
  width: auto;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.slider-bus .swiper-pagination-fraction .swiper-pagination-current {
  font-size: 60px;
  font-weight: 700;
  position: relative;
  color: transparent;
  -webkit-text-stroke: .5px #fff;
}

.slider-bus .swiper-pagination-fraction .swiper-pagination-current:after {
  content: '/';
  color: #fff;
  position: relative;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 100;
}

.slider-bus .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider-bus .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider-bus .swiper-slide-active .caption .thin,
.slider-bus .swiper-slide-active .caption .simple-btn,
.slider-bus .swiper-slide-active .caption .butn,
.slider-bus .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slider-bus .caption .thin {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 5px;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.slider-bus .caption h1 {
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 4px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,
 visibility .5s;
  -o-transition: opacity .5s,
 visibility .5s;
  transition: opacity .5s,
 visibility .5s;
}

.slider-bus .caption p {
  color: #eee;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.slider-bus .caption .simple-btn, .slider-bus .caption .butn {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.slider-bus .ontop {
  position: relative;
  z-index: 5;
}

.curve-bg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.pages-header {
  min-height: 75vh;
  padding: 150px 0 0;
  position: relative;
}

.pages-header .cont h1 {
  font-size: 55px;
}

.pages-header .half {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: -1;
  overflow: hidden;
}

.page-header .cont {
  padding: 220px 0 50px;
}

.page-header .cont h1 {
  font-size: 80px;
  font-weight: 600;
}

.page-header .img-wrapper {
  position: relative;
  min-height: 70vh;
}

.page-header .img-wrapper .title {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  text-align: right;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  z-index: 3;
}

.page-header .img-wrapper .title h3 {
  font-size: 100px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.page-header .img-wrapper .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.proj-det.bg-img {
  min-height: 85vh;
  padding-bottom: 100px;
}

.page-header.proj-det.bg-img .item h6 {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.page-header.proj-det.bg-img .item p {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.page-header.proj-det.bg-img .item p a {
  margin: 0 7px;
}

.page-header.proj-det.bg-img .item p a:first-of-type {
  margin-left: 0;
}

.page-header.proj-det.bg-img .item p a:last-of-type {
  margin-right: 0;
}

.page-header.proj-det .img-wrapper {
  min-height: 80vh;
}
.tab-content>.active{
  display: flex;
  justify-content: center;
}
.works-header {
  min-height: 80vh !important;
  /* background: linear-gradient(45deg, #12131999, #12131999),url(../img/banner_img_8.jpg); */
  background-size: cover !important;  
  background-attachment: fixed !important;
  background-position: center !important;
}

.works-header.hfixd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.works-header .capt h4 {
  font-weight: 200;
  font-size: 24px;
  line-height: 1.8;
}

.works-header .capt h1 {
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.works-header .capt h1 span {
  display: block;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  color: #12c2e9;
}

.works-header .capt .bactxt {
  font-size: 13vw;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  opacity: .5;
}

.static {
  position: static;
}

.circle-bg {
  position: relative;
}

.circle-bg .circle-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.circle-bg .circle-color.fixed {
  position: fixed;
  pointer-events: none;
  opacity: .5;
}

.circle-bg .gradient-circle {
  position: absolute;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 70%;
  background-image: -webkit-linear-gradient(45deg, #059dff, #36c6f0);
  background-image: -o-linear-gradient(45deg, #059dff, #36c6f0);
  background-image: linear-gradient(45deg, #059dff, #36c6f0);
  opacity: .25;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}

.circle-bg .gradient-circle.two {
  left: auto;
  top: -250px;
  right: -250px;
  bottom: auto;
  background-image: -webkit-linear-gradient(45deg, #fb5343, #6549d5);
  background-image: -o-linear-gradient(45deg, #fb5343, #6549d5);
  background-image: linear-gradient(45deg, #fb5343, #6549d5);
}

.main-content {
  background: #fff;
  position: relative;
  z-index: 3;
  overflow-x: hidden !important;
}

/* ====================== [ End Slider ] ====================== */



.about-us .img {
  padding-left: 150px;
  padding-right: 50px;
  position: relative;
}

.about-us .img:before {
  content: '';
  position: absolute;
  top: -50px;
  right: 0;
  width: 400px;
  height: 400px;
  background-image: url(../img/dots.png);
  background-repeat: repeat;
  opacity: .3;
  z-index: -1;
}

.about-us .img .stauts {
  position: absolute;
  bottom: 50px;
  left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about-us .img .stauts .item {
  padding: 30px 20px;
  background: #272727;
  margin-right: 50px;
  position: relative;
  color: #fff;
}

.about-us .img .stauts .item:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid rgba(100, 100, 100, 0.1);
}

.about-us .img .stauts .item:first-of-type {
  background: #f5f5f5;
  color: #121a21;
}

.about-us .img .stauts .item h4 {
  font-size: 45px;
  font-weight: 800;
  display: table-cell;
  vertical-align: middle;
  padding-right: 20px;
}

.about-us .img .stauts .item h4 span {
  font-size: 30px;
  font-weight: 500;
  margin-left: 5px;
}

.about-us .img .stauts .item h6 {
  font-size: 15px;
  display: table-cell;
  vertical-align: middle;
}

.about .ftbox ul {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about .ftbox ul li {
  width: 100%;
  padding: 25px 20px;
  text-align: center;
  background: #f5f5f5;
  border-radius: 5px;
  position: relative;
}

.about .ftbox ul li.space {
  margin: 0 10px;
}

.about .ftbox ul li .icon {
  color: #12c2e9;
  font-size: 35px;
  margin-bottom: 20px;
}
.about .ftbox ul li img{
  
  width: 45px;
  margin-bottom: 20px;

}
.about .ftbox ul li h6 {
  font-size: 14px;
  font-weight: 400;
}

.about .ftbox ul li .dots span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #eee;
  position: absolute;
  right: 10px;
  bottom: 12px;
  opacity: .1;
}

.about .ftbox ul li .dots span:first-of-type {
  bottom: 18px;
}

.about .ftbox ul li .dots span:last-of-type {
  right: 16px;
}




.extra-text {
  font-size: 22px;
}


.js-scroll__content {
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  will-change: transform;
}

/* ====================== [ End Intro ] ====================== */
/* ====================== [ Start services ] ====================== */
.services {
  position: relative;
background-color: #fafafa;
}

.services .container {
  position: relative;
  z-index: 2;
}

.services .item-box {
  padding: 80px 30px 40px;
  text-align: center;
  border: 1px solid gainsboro;
  background: transparent;
  min-height: 470px;
  transition: all 0.3s ease-in-out;

}
.services .item-box:hover{
  border: 1px solid gainsboro;
color: white;
  border-image: -webkit-gradient(linear, left top, right top, from(#12c2e9) 1, color-stop(#c471ed), to(#f64f59));
  border-image: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59) 1;
  border-image: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59) 1;
  border-image: linear-gradient(to right, #12c2e9, #c471ed, #f64f59) 1;
  box-shadow: 0 0 20px 5px #00000017;
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  transition: all 0.3s ease-in-out;
}
.services .item-box p{
  transition: all 0.3s ease-in-out;
}
.services .item-box:hover p{
  transition: all 0.3s ease-in-out;
  color: white;
}

.services .item-box p span.color-font{
  transition: all 0.3s ease-in-out;
}
.services .item-box:hover p span.color-font{
  color: white;
  background: none;
  transition: all 0.3s ease-in-out;
  -webkit-text-fill-color: white;
}
.services .item-box .more-stroke {
  margin-top: 10px;
}
.services .item-box:hover .icon:after{
  background: white;
  z-index: 0;
}
.services .item-box .more-stroke span {
  width: 0;
  height: 2px;
  background: rgb(255, 255, 255);
  position: relative;
}

.services .item-box .more-stroke span:after, .services .item-box .more-stroke span:before {
  content: '';
  width: 0;
  height: 2px;
  background: rgb(255, 255, 255);
  position: absolute;
  right: -1px;
}

.services .item-box .more-stroke span:after {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  top: -4px;
}

.services .item-box .more-stroke span:before {
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
  top: 4px;
}

.services .item-box:hover:before {
  opacity: 1;
}

.services .item-box:hover .more-stroke span {
  width: 30px;
  -webkit-transition: width .3s ;
  -o-transition: width .3s ;
  transition: width .3s ;
}

.services .item-box:hover .more-stroke span:after, .services .item-box.active .more-stroke span:before {
  width: 12px;
  -webkit-transition: width .3s ;
  -o-transition: width .3s ;
  transition: width .3s ;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.services .item-box:hover .more-stroke span {
  width: 30px;
}

.services .item-box:hover .more-stroke span:after, .services .item-box:hover .more-stroke span:before {
  width: 12px;
}

.services .item-box .icon {
  position: relative;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 40px;
  margin-bottom: 30px;
}

.services .item-box .icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  z-index: -1;
  opacity: .1;
}

.services .item-box h6 {
  line-height: 1.8;
  text-transform: capitalize;
  margin-bottom: 10px;
}



.services .item {
  text-align: center;
  padding: 60px;
  background: #151921;
  position: relative;
}

.services .item .icon {
  color: #12c2e9;
  font-size: 40px;
  margin-bottom: 40px;
}

.services .item h6 {
  margin-bottom: 15px;
}

.services.lficon .item-box {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  padding: 60px 40px;
  border-radius: 20px;
}

.services.lficon .item-box .icon {
  text-align: center;
}

.services.lficon .item-box .cont {
  padding-left: 30px;
}


/* ====================== [ End services ] ====================== */
/* ====================== [ Start brands ] ====================== */
.clients .brands:hover img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients .brands:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients .brands:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients .brands .item {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: 160px;
  line-height: 160px;
}

.clients .brands .item.no-bord {
  border: 0;
  text-align: center;
  height: 100px;
  line-height: 100px;
}

.clients .brands .img {
  position: relative;
}

.clients .brands .img img {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  max-width: 75px;
  margin: auto;
}

.clients .brands .img .link {
  font-size: 13px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.clients .bord .item {
  margin-bottom: 30px;
}

.clients-brand .brands:hover .img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients-brand .brands:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients-brand .brands:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients-brand .item {
  position: relative;
  text-align: center;
}

.clients-brand .item .link {
  font-size: 16px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.clients-brand .img {
  max-width: 85px;
  margin: auto;
  position: relative;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

/* ====================== [ End brands ] ====================== */
/* ====================== [ Start Work ] ====================== */
.portfolio {
  position: relative;
}

.portfolio .filtering {
  padding-left: 50px;
}

.portfolio .filtering .filter {
  padding: 15px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  display: inline-block;
}

.portfolio .filtering span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

.portfolio .filtering span.active {
  color: #12c2e9;
}

.portfolio .filtering span:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #12c2e9;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 40%;
}

.portfolio .filtering span:last-of-type:after {
  display: none;
}

.portfolio .filtering.smplx .filter {
  padding: 0;
  background: transparent;
  border-radius: 0;
  display: inline-block;
}

.portfolio .filtering.smplx span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

.portfolio .filtering.smplx span.active {
  color: #12c2e9;
}

.portfolio .filtering.smplx span:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #12c2e9;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 40%;
}

.portfolio .filtering.smplx span:last-of-type:after {
  display: none;
}

.portfolio .gallery .items {
  padding: 0 50px;
  margin: 50px 0;
}

.portfolio .gallery .items.lg-mr {
  margin-top: 100px !important;
}

.portfolio .gallery .items .item-img {
  overflow: hidden;
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.portfolio .gallery .items .item-img > a {
  display: block;
}

.portfolio .gallery .items .item-img:hover {
  -webkit-clip-path: inset(5px 10px);
  clip-path: inset(5px 10px);
}

.portfolio .gallery .items .item-img:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.portfolio .gallery .items .item-img:hover .cont {
  opacity: 1;
}

.portfolio .gallery .items .item-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.portfolio .gallery .items .item-img .cont {
  position: absolute;
  bottom: 15px;
  left: 0;
  text-align: left;
  padding-left: 30px;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.portfolio .gallery .items .item-img .cont span {
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
}

.portfolio .gallery .items .item-img .cont span a {
  margin: 0 10px 0 0;
}

.portfolio .gallery .items .cont {
  margin-top: 30px;
  text-align: center;
}

.portfolio .gallery .items .cont h6 {
  font-weight: 600;
  margin-bottom: 5px;
}

.portfolio .gallery .items .cont span {
  color: #999;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 13px;
}

.portfolio .gallery .items .cont span a {
  margin: 0 5px;
}

.portfolio .gallery .bords .item-img {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.03);
}

.portfolio .gallery .bords .cont {
  border: 1px solid rgba(255, 255, 255, 0.03);
  margin-top: 0;
  border-top: 0;
  padding: 10px;
}

.portfolio.three-column .gallery .items {
  padding: 0 15px;
  margin: 25px 0;
}


.portfolio-cr .filtering .filter {
  display: inline-block;
}

.portfolio-cr .filtering span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 8px 20px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.portfolio-cr .filtering span:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #12c2e9;
  position: absolute;
  left: 0;
  bottom: -10px;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.portfolio-cr .filtering .active {
  color: #12c2e9;
}

.portfolio-cr .filtering .active:after {
  bottom: 0;
  opacity: 1;
}

.portfolio-cr .items {
  padding: 0 15px;
  margin-top: 30px;
  width: 50%;
  position: relative;
  overflow: hidden;
}

.portfolio-cr .items:hover .cont {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-cr .items.width2 {
  width: 25%;
}

.portfolio-cr .items .cont {
  position: absolute;
  bottom: 15px;
  left: 30px;
  right: 30px;
  padding: 20px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.48);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  opacity: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.portfolio-cr .items .cont.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.portfolio-cr .items .cont h6 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 5px;
}

.portfolio-cr .items .cont span {
  margin-left: auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 12px;
}

.portfolio-cr .items .cont span a {
  opacity: .7;
  margin: 0 5px;
  line-height: 1.2;
}



.works .lg-space > div {
  padding: 0 50px;
  margin-bottom: 50px;
}


/* ====================== [ End Work ] ====================== */

/* ====================== [ Start progress bar ] ====================== */
.skills-circle .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skills-circle .skill {
  position: relative;
}

.skills-circle .skill span {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50px) translateY(-50px);
  -ms-transform: translateX(-50px) translateY(-50px);
  transform: translateX(-50px) translateY(-50px);
  font-size: 20px;
  font-weight: 300;
}

.skills-circle .cont {
  padding-top: 25px;
  padding-left: 25px;
}

.skills-circle .cont span {
  font-size: 12px;
  font-weight: 300;
  opacity: .9;
  margin-bottom: 10px;
}

.skills-circle .cont h6 {
  font-size: 15px;
  font-weight: 500;
}

.skills-box .skill-item {
  margin-bottom: 50px;
}

.skills-box .skill-item:last-of-type {
  margin-bottom: 0;
}

.skills-box h6 {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.skills-box .skill-progress {
  height: 7px;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
}

.skills-box .skill-progress .progres {
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s;
}

.skills-box .skill-progress .progres:after {
  content: attr(data-value);
  position: absolute;
  right: 10px;
  top: -45px;
  padding: 7px 15px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 12px;
  font-weight: 600;
}

.skills-box .skill-progress .progres:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 15px;
  bottom: 12px;
}

/* ====================== [ End progress bar ] ====================== */

/* ====================== [ Start Team ] ====================== */


#circle {
  position: absolute;
  top: -30%;
  right: -50%;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .4s;
  -o-transition: opacity .4s;
  transition: opacity .4s;
}

#circle text {
  font-size: 13px;
  letter-spacing: 4px;
}

#circle svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 8s;
  -moz-animation-duration: 8s;
  -ms-animation-duration: 8s;
  -o-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}

@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}

@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

/* ====================== [ End Team ] ====================== */
/* ====================== [ Start Testimonials ] ====================== */
.testimonials .container-fluid {
  width: 140%;
  margin-left: -20%;
}

.testimonials .item {
  text-align: left;
  padding: 80px 40px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  background: -webkit-linear-gradient(323.53deg, #ccc 6.69%, rgba(2, 2, 30, 0) 85.43%);
  background: -o-linear-gradient(323.53deg, #ccc 6.69%, rgba(2, 2, 30, 0) 85.43%);
  background: linear-gradient(126.47deg, #ccc 6.69%, rgba(2, 2, 30, 0) 85.43%);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonials .item.slick-center {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.testimonials .item p {
  color: #111;
  font-size: 28px;
  font-weight: 600;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.testimonials .item .info {
  min-width: 120px;
}

.testimonials .item .info .img {
  width: 80px;
  height: 80px;
  border: 7px solid #eee;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.testimonials .item .info .author-name {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
}

.testimonials .item .info .author-details {
  font-size: 13px;
}

.testimonials .slick-dots {
  bottom: -30px;
}

.testimonials .slick-dots li {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #eee;
}

.testimonials .slick-dots li.slick-active {
  background: #eee;
}

.testimonials .slick-dots li button {
  display: none;
}

.testimonials .arrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.testimonials .arrows .container {
  position: relative;
  height: 100%;
}

.testimonials .arrows .next, .testimonials .arrows .prev {
  position: absolute;
  top: 50%;
  right: -50px;
  width: 50px;
  height: 50px;
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #eee;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.testimonials .arrows .next:hover, .testimonials .arrows .prev:hover {
  border-color: #12c2e9;
}

.testimonials .arrows .prev {
  right: auto;
  left: -50px;
}

.testimonials:hover .arrows .next, .testimonials:hover .arrows .prev {
  opacity: 1;
  right: 17%;
}

.testimonials:hover .arrows .prev {
  left: 17%;
}

.testimonials.lftstl .item {
  text-align: left;
}

.testimonials.lftstl .item .info .author .lxleft {
  display: table-cell;
  vertical-align: middle;
}

.testimonials.lftstl .item .info .author .lxleft .img {
  margin: 0;
}

.testimonials.lftstl .item .info .author .fxright {
  display: table-cell;
  padding: 20px;
}

.testimonials.lftstl .slick-dots {
  text-align: right;
}

.block-sec .vid-area {
  position: absolute;
  top: -100px;
  left: 10%;
  padding: 30px 15px;
  background: #ffff;
  text-align: center;
  z-index: 2;
}

.block-sec .vid-area:after {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: #fff;
  opacity: .2;
  z-index: -1;
}

.block-sec .vid-area .vid-icon {
  margin-top: 50px;
}

.block-sec .vid-area .vid-icon .vid {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  border: 2px solid #000;
}

.block-sec .vid-area .vid-icon .vid span {
  font-size: 10px;
  color: #000;
}

.block-sec .vid-area .text {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 30px;
}

.block-sec .testim-box {
  position: relative;
  padding: 80px 40px;
  margin-bottom: -120px;
  z-index: 2;
}

.block-sec .testim-box:after {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
   background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 

  z-index: -4;
}

.block-sec .testim-box:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: #111215;
  z-index: -1;
}

.block-sec .testim-box .head-box h6 {
  color: #12c2e9;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}

.block-sec .testim-box .head-box h4 {
  margin-bottom: 40px;
}

.block-sec .testim-box .item p {
  font-size: 17px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.block-sec .testim-box .item .info .img {
  display: table-cell;
  vertical-align: middle;
}

.block-sec .testim-box .item .info .img .img-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.block-sec .testim-box .item .info .cont {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px;
}

.block-sec .testim-box .item .info .cont .author-name {
  font-size: 16px;
  margin-bottom: 5px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.block-sec .testim-box .item .info .cont .author-details {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
}

.block-sec .testim-box .slick-dots {
  text-align: right;
}

.block-sec .testim-box .slick-dots li {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #12c2e9;
}

.block-sec .testim-box .slick-dots li.slick-active {
  background: #12c2e9;
}

.block-sec .testim-box .slick-dots li button {
  display: none;
}

.clients-brand .item a {
  width: 80px;
  opacity: .5;
}

.clients-brand .item a:hover {
  opacity: 1;
}

/* ====================== [ End Testimonials ] ====================== */

/* ====================== [ Start Call To Action  ] ====================== */
.call-action {
  background-attachment: fixed;
}

.call-action .content h6 {
  color: #12c2e9;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}

.call-action .content h6 span {
  color: #12c2e9;
}

.call-action .content h2 {
  font-weight: 700;
  font-size: 4vw;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  color: transparent;
}

.call-action .content h2 span {
  color: transparent;
}

.call-action .content h2 b span {
  color: #fff;
  -webkit-text-stroke-width: 0;
}

.call-action .content h2 .whitespace {
  width: 18px;
}

.call-action .content p {
  font-size: 17px;
  font-weight: 300;
}

.call-action .btn-curve {
  border: 2px solid;
  line-height: 2;
}

.call-action .btn-curve span {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

.call-action .btn-curve:hover span {
  color: #0c0f16;
}

.call-action .tlink {
  margin-left: auto;
  border-bottom: 1px solid #eee;
}

.call-action.next {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.call-action.next:after {
  display: none;
}

.call-action.next:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: #0a0a0a;
  opacity: 1;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.call-action.next .content h2 {
  font-size: 5vw;
  font-weight: 700;
}

.call-action.next .content h2 b {
  font-weight: 700;
}

.call-action.next .nxt-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  background-size: cover;
  background-position: center;
  z-index: -1;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.call-action.next:hover:before {
  opacity: .9;
}

.call-action.next:hover .nxt-img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 10s linear;
  -o-transition: all 10s linear;
  transition: all 10s linear;
}

/* ====================== [ End Call To Action  ] ====================== */

/* ====================== [ Start contact ] ====================== */
.contact-sec .form .form-group {
  margin-bottom: 30px;
}

.contact-sec .form .form-group input, .contact-sec .form .form-group textarea {
  width: 100%;
  padding: 15px 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: transparent;
}

.contact-sec.style2 .form input, .contact-sec.style2 .form textarea {
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.contact .form input, .contact .form textarea {
  color: #fff;
  width: 100%;
  padding: 15px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.contact .form textarea {
  padding: 15px;
  min-height: 140px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 30px;
}

.contact .cont-info h3 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.contact .cont-info .item h5 {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin: 10px 0;
}

.contact .cont-info .item h6 {
  font-size: 14px;
  font-weight: 300;
  color: #555;
  line-height: 1.7;
}

.contact .cont-info .social a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 50%;
  margin-right: 5px;
  font-size: 13px;
}

.map iframe{
  width: 100%;
  height: 60vh;
}

/* ====================== [ End contact ] ====================== */
/* ====================== [ Start Footer ] ====================== */
footer {
  color: #fff;
  padding: 100px 0;
  background: #18191d;
}

footer.sub-bg {
  background: #18191d;
  position: relative;
}

footer .item .title h5 {
  margin-bottom: 30px;
}

footer .item li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}

footer .item li:last-of-type {
  margin-bottom: 0;
}

footer .item li h6 {
  font-size: 14px;
  font-weight: 600;
}

footer .item li .icon {
  font-size: 30px;
  margin-right: 30px;
}

footer .item li .img {
  width: 150px;
  margin-right: 15px;
}

footer .item li .sm-post p {
  font-size: 13px;
}

footer .item li .sm-post .date {
  font-size: 12px;
  color: #12c2e9;
}

footer .item li .subscribe {
  width: 100%;
  position: relative;
}

footer .item li .subscribe input {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: transparent;
  color: #fff;
  font-size: 13px;
}

footer .item li .subscribe .subs {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: #12c2e9;
  color: #000;
}

footer .item .logo {
  margin-bottom: 50px;
}

footer .item .logo img {
  width: 220px;
}

footer .item .social a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 13px;
  margin-right: 10px;
}

footer .item .copy-right {
  margin-top: 30px;
}

footer .item .copy-right p {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
}

footer .item .copy-right p a {
  color: #12c2e9;
}
.about .content .co-tit {
  font-weight: 400;
  margin-bottom: 30px;
}

.about .img-mons .img1, .about .img-mons .img2, .about .img-mons .img3 {
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.about .img-mons .img1 img, .about .img-mons .img2 img, .about .img-mons .img3 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.about .img-mons .img2 {
  height: 240px;
}

.about .img-mons .img3 {
  width: 75%;
  margin-top: 20px;
}

.about .img-mons .img3.exp {
  background: #18191d;
  padding: 30px;
}

.about .img-mons .img3.exp h2 {
  font-weight: 800;
  margin-bottom: 5px;
}

.about .img-mons .img3.exp h6 {
  letter-spacing: 2px;
}

/* ====================== [ End Footer ] ====================== */

/* .main_image{
	display: block;
	height: 350px !important;
	overflow: hidden;
	position: relative;
}
.main_image img {
	bottom: -1210px;
	width: 100%;
	height: auto;
	position: absolute;
	z-index: 0;
  margin:0;
  padding:0;
	-webkit-transition: top 11s;
	-moz-transition: top 11s;
	-ms-transition: top 11s;
	-o-transition: top 11s;
	transition: bottom 11s;
}
.main_image:hover img {
  bottom: 0;
  -webkit-transition: all 11s;
  -moz-transition: all 11s;
  -ms-transition: all 11s;
  -o-transition: all 11s;
  transition: all 11s !important;
} */

/* .main_image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 600px;
  top: 0;
  
} */


/* .main_image img:hover {
  transition:all 7s ease-in-out;
} */

/* ====================== [ Start app-price  ] ====================== */
.app-price .item {
  padding: 50px 30px;
  border: 1px solid rgba(76, 76, 76, 0.1);
  margin-top: 50px;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 10px 60px rgba(76, 76, 76, 0.1);
  box-shadow: 0px 5px 20px rgba(76, 76, 76, 0.1);
}

.app-price .item.sm-padding {
  padding: 50px 40px;
}

.app-price .item.sm-padding .feat li .icon {
  margin-right: 10px;
}
.app-price .item:hover{
  box-shadow: 0px 10px 60px rgba(76, 76, 76, 0.1);
  transform: scale(1.05);
}
.app-price .item{
transition: all 0.3s ease-in-out;
}
.app-price .item:hover{
  transition: all 0.3s ease-in-out;
  background: aliceblue;
  /* background: #1fbae4; */
  /* color: white; */
}
/* .app-price .item .feat li{
  transition: all 0.3s ease-in-out;
}
.app-price .item:hover .feat li{
  color: white;
}
.app-price .item .buton.butn-gr{
transition: all 0.3s ease-in-out;
} */
/* .app-price .item:hover .buton.butn-gr{
  background: white !important;
  color: black;
} */


.app-price .item.active {
  border-color: transparent;

}

.app-price .item .amount h3 {
  font-size: 50px;
  font-weight: 500;
  color: #f45162;
  position: relative;
  display: inline-block;
  line-height: 1;
}

.app-price .item .amount h3 span {
  position: absolute;
  left: -20px;
  top: 5px;
  color: #0f0f1e;
  font-size: 25px;
}

/* .app-price .item .order .buton {
  width: 100%;
} */

.app-price .item .feat ul {
  margin: 0;
  padding: 0;
}

.app-price .item .feat li {
  color: #555;
  margin-top: 15px;
  display: flex;
}

.app-price .item .feat li.disbl {
  opacity: .3;
}

.app-price .item .feat li .icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background: #f45162;
  margin-right: 20px;
}

.app-price .item .feat li .icon svg {
  width: 10px;
  fill: #fff;
  position: relative;
}

/* ====================== [ End app-price  ] ====================== */

.buton {
  min-width: 220px;
  text-align: center;
  padding: 15px 30px;
  font-weight: 600;
}

.buton.butn-gr {
  background-color: #f45162;
  background-image: -webkit-gradient(linear, right top, left top, from(#17c0e9), color-stop(#c96ddd), to(#f45162));
  background-image: -webkit-linear-gradient(right, #17c0e9, #c96ddd, #f45162);
  background-image: -o-linear-gradient(right, #17c0e9, #c96ddd, #f45162);
  background-image: linear-gradient(to left, #17c0e9, #c96ddd, #f45162);
  border: 1px solid transparent;
  color: #fff;
}

.buton.butn-gr svg {
  fill: #fff;
}

.buton.butn-gr:hover {
  background: #0f0f1e;
}

.buton.butn-gray {
  background: #edf3ff;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.buton.butn-gray:hover {
  background: #0f0f1e;
  color: #fff;
}

.buton.butn-gray:hover svg {
  fill: #fff;
}

.buton.butn-bord-dark {
  border: 1px solid #0f0f1e;
}

.main_image .main_image_img{
  object-position: top;
}
.main_image .main_image_img:hover{
  object-position: bottom;
}
.main_image img {
  /* width: 100%;
  height: auto !important;
  transition:all 4s ease-in-out !important; */
  width: 100% !important;
  object-fit: cover;

  height: 600px !important;
  transition: 7s all ease !important;
}

.scrollbar_pricing{
  height: 280px;
  width: 100%;
  overflow-y: scroll;
  padding-left: 15px !important;
}
.scrollbar_pricing::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}
.scrollbar_pricing::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
  background-color: transparent;
}
.scrollbar_pricing::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  background-image:linear-gradient( 0deg, #12c2e9, #c471ed, #f64f59);
  
}


.app-price .nav-tabs {
  padding-left: 50px;
}

.app-price .nav-tabs  {
  width: fit-content;
  padding: 18px 25px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  display: flex;
  box-shadow: none;
  border: 0;
  margin: auto;
}

.app-price .nav-tabs .nav-link {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
  padding: 0;
  border: 0;
  background: 0;
}
.app-price .nav-tabs .nav-link:hover{
display: block;
}
.app-price .nav-tabs .nav-link.active {
  color: #12c2e9;
}
.app-price .nav-tabs .nav-item{
  position: relative;
}
.app-price .nav-tabs .nav-item:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #12c2e9;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 40%;
}

.app-price .nav-tabs .nav-item:last-of-type:after {
  display: none;
}


.work-carousel2 .swiper-slide {
  padding: 0px 10px 50px;
}

.work-carousel2 .swiper-slide .img {
  border-radius: 3px;
  overflow: hidden;
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
}

.work-carousel2 .swiper-slide-active .img {
  -webkit-box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
}

.work-carousel2 .swiper-slide-active .img:after {
  opacity: 1 !important;
}

.work-carousel2 .swiper-slide-active .cont h6, .work-carousel2 .swiper-slide-active .cont h4 {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

.work-carousel2 .swiper-pagination-progressbar {
  position: absolute;
  bottom: 0;
  top: auto;
  right: auto;
  left: calc(50% - 120px);
  width: 240px;
  height: 2px;
  border-radius: 10px;
  background: #eee;
}

.work-carousel2 .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  border-radius: 10px;
  background: #1e1e1e;
}

.work-carousel2 .swiper-button-next,
.work-carousel2 .swiper-button-prev {
  color: #000;
  width: 60px;
  height: 60px;
  border-radius: 0;
  border: 0;
  background: #fff;
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.work-carousel2 .swiper-button-next:after,
.work-carousel2 .swiper-button-prev:after {
  display: none;
}

.work-carousel2:hover .swiper-button-next,
.work-carousel2:hover .swiper-button-prev {
  opacity: 1;
}

.work-carousel2 .content {
  position: relative;
}

.work-carousel2 .content .img {
  position: relative;
  line-height: 0;
}

.work-carousel2 .content .img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), to(transparent));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  opacity: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.work-carousel2 .content .cont {
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: #fff;
}

.work-carousel2 .content .cont h6 {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.work-carousel2 .content .cont h4 {
  font-weight: 600;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.work-carousel2.mcolumn .swiper-slide {
  padding: 0px;
}

.work-carousel2.mcolumn .swiper-slide .img {
  border-radius: 0;
}

.work-carousel2.mcolumn .swiper-slide-active .img {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.work-carousel2.mcolumn .swiper-slide-active .img:after {
  opacity: 1 !important;
}

.Custom_btn{
  margin-top: 170px;
  text-align: center;
  position: relative;
}
.arrow_img_1{
  width: 130px;
  position: absolute;
  right: 36%;
  top: 0;
  transform: rotate(30deg) scaleX(-1)  translateY(-80%) ;
}
.custom_text{
  position: absolute;
  right: 28%;
  top: 0;
  transform: translateY(-400%);
}
.custom_text h3{
  font-size: 18px;
  font-family: cursive;
}


.cbh-phone {
  display: block;
  position: fixed;
  left: -15px;
  bottom: 140px;
  visibility: hidden;
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 999;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}
.cbh-phone.cbh-show {
  visibility: visible;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
.cbh-phone.cbh-static1 {
  opacity: 0.6;
}
.cbh-phone.cbh-hover1 {
  opacity: 1;
}
.cbh-ph-circle {
  width: 110px;
  height: 110px;
  top: 40px;
  left: 40px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -o-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cbh-phone.cbh-active .cbh-ph-circle1 {
  -webkit-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -moz-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -ms-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  -o-animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
  animation: cbh-circle-anim 1.1s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-circle {
  -webkit-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -moz-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -ms-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -o-animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
}
.cbh-phone.cbh-hover .cbh-ph-circle {
  border-color: rgba(0, 175, 242, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-circle {
  border-color: rgba(117, 235, 80, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-green .cbh-ph-circle {
  border-color: rgba(0, 175, 242, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-circle {
  border-color: rgba(204, 204, 204, 1);
  opacity: 0.5;
}
.cbh-phone.cbh-gray .cbh-ph-circle {
  border-color: rgba(117, 235, 80, 1);
  opacity: 0.5;
}
.cbh-ph-circle-fill {
  width: 74px;
  height: 74px;
  top: 58px;
  left: 58px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cbh-phone.cbh-active .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -moz-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -ms-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  -o-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: cbh-circle-fill-anim 1.7s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;
}
.cbh-phone.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-green .cbh-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-circle-fill {
  background-color: rgba(204, 204, 204, 0.5);
  opacity: 0.75 !important;
}
.cbh-phone.cbh-gray .cbh-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 1 !important;
}
.cbh-ph-img-circle1 {
  width: 50px;
  height: 50px;
  top: 70px;
  left: 70px;
  position: absolute;
  background-image: url(../img/wpp-icon.png);
  background-size: 40px 40px;
  background-color: rgba(30, 30, 30, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 1;
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  animation: cbh-circle-img-anim 1s infinite ease-in-out;
}
.cbh-phone.cbh-active .cbh-ph-img-circle1 {
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
  animation: cbh-circle-img-anim 1s infinite ease-in-out !important;
}
.cbh-phone.cbh-static .cbh-ph-img-circle1 {
  -webkit-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -moz-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -ms-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  -o-animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
  animation: cbh-circle-img-anim 0s infinite ease-in-out !important;
}
.cbh-phone.cbh-hover .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-green.cbh-hover .cbh-ph-img-circle1:hover {
  background-color: rgba(117, 235, 80, 1);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgba(0, 175, 242, 1);
}
.cbh-phone.cbh-gray.cbh-hover .cbh-ph-img-circle1 {
  background-color: rgba(204, 204, 204, 1);
}
.cbh-phone.cbh-gray .cbh-ph-img-circle1 {
  background-color: rgba(117, 235, 80, 1);
}
@-moz-keyframes cbh-circle-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }
  30% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    -o-opacity: 0.5;
  }
  100% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.6;
    -moz-opacity: 0.6;
    -webkit-opacity: 0.6;
    -o-opacity: 0.1;
  }
}
@-webkit-keyframes cbh-circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}
@-o-keyframes cbh-circle-anim {
  0% {
    -o-transform: rotate(0deg) kscale(0.5) skew(1deg);
    -o-opacity: 0.1;
  }
  30% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-opacity: 0.5;
  }
  100% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    -o-opacity: 0.1;
  }
}
@keyframes cbh-circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
  }
}
@-moz-keyframes cbh-circle-fill-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -moz-transform: rotate(0deg) -moz-scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-webkit-keyframes cbh-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-o-keyframes cbh-circle-fill-anim {
  0% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@keyframes cbh-circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@keyframes cbh-circle-img-anim {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-moz-keyframes cbh-circle-img-anim {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-webkit-keyframes cbh-circle-img-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
@-o-keyframes cbh-circle-img-anim {
  0% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  100%,
  50% {
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }
}
.cbh-ph-img-circle1 {
}
.cbh-phone.cbh-green .cbh-ph-circle {
  border-color: rgb(0, 242, 164);
}
.cbh-phone.cbh-green .cbh-ph-circle-fill {
  background-color: rgb(0, 242, 164);
}
.cbh-phone.cbh-green .cbh-ph-img-circle1 {
  background-color: rgb(46, 203, 113);
}



.mail_main {
  display: block;
  position: fixed;
  left:  30px;
  bottom: 100px;
  background: aliceblue;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  z-index: 999;
  transform: scale(0.6);
}

.envelope {
  position: relative;
  -webkit-animation: mail_anm 2.3s infinite ease-in-out;
  -moz-animation: mail_anm 2.3s infinite ease-in-out;
  -ms-animation: mail_anm 2.3s infinite ease-in-out;
  -o-animation: mail_anm 2.3s infinite ease-in-out;
  animation: mail_anm 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1 !important;
  align-self: center;
  top: 15px;
  margin: 0 auto;
  width: 100px;
  height: 75px;
  
  background-color: #94BDE5;
}

.mail_main:hover {
  cursor: pointer;
}

.mail_main:hover .moves {
    transform: rotateX(180deg) translateY(50px);
  -webkit-transform: rotateX(180deg) translateY(50px);
  -ms-transform: rotateX(180deg) translateY(50px);
    transition: .001s 0s;
}

.container:hover .letter {
  z-index:40;
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);

}

.envelope-front {
  width: 100px;
  height: 75px;
background-color: #94BDE5;
  position: absolute;
  z-index:50;
  -webkit-clip-path: polygon(50% 55%, 100% 1%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 55%, 100% 1%, 100% 100%, 0 100%, 0 0);
}

.envelope-flap {
  width: 0; 
  height: 0; 
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #6393BF;
  position: absolute;
}

.envelope-flap.moves {
  z-index:30;
  transition: .001s .45s;
}

.letter {
  height:60px;
  width:75px;
  display:block;
  background-color:#ffffff;
  position:absolute;
  left:12.5px;
  z-index:20;
  transition: all .5s ease-in-out;
}


@keyframes mail_anm {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
  
  }
  50% {
    transform: rotate(0deg) scale(0.6) skew(1deg);
  }
  100% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
  }
}



.whatsapp-chat a:link,
.whatsapp-chat a:visited {
  color: #444;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

/* CSS Multiple Whatsapp Chat */
.whatsapp-name {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 0.5;
}

#whatsapp-chat {
  box-sizing: border-box !important;
  outline: none !important;
  position: fixed;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  bottom: 150px;
  right: 30px;
  overflow: hidden;
  z-index: 99;
  animation-name: showchat;
  animation-duration: 1s;
  transform: scale(1);
}

a.blantershow-chat {
  /*   background: #009688; */
  background: #fff;
  color: #404040;
  position: fixed;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  z-index: 98;
  bottom: 95px;
  right: 30px;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
}

a.blantershow-chat svg {
  transform: scale(1.2);
  margin: 0 10px 0 0;
}

.header-chat {
  /*   background: linear-gradient(to right top, #6f96f3, #164ed2); */
  background: #009688;
  background: #095e54;
  color: #fff;
  padding: 20px;
}

.header-chat h3 {
  margin: 0 0 10px;
}

.header-chat p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  color: white;
}

.info-avatar {
  position: relative;
}

.info-avatar img {
  border-radius: 100%;
  width: 50px;
  float: left;
  margin: 0 10px 0 0;
}

a.informasi {
  padding: 20px;
  display: block;
  overflow: hidden;
  animation-name: showhide;
  animation-duration: 0.5s;
}

a.informasi:hover {
  background: #f1f1f1;
}

.info-chat span {
  display: block;
}

#get-label,
span.chat-label {
  font-size: 12px;
  color: #888;
}

#get-nama,
span.chat-nama {
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 700;
  color: #222;
}

#get-label,
#get-nama {
  color: #fff;
}

span.my-number {
  display: none;
}

/* .blanter-msg {
  color: #444;
  padding: 20px;
  font-size: 12.5px;
  text-align: center;
  border-top: 1px solid #ddd;
} */
textarea#chat-input {
  border: none;
  font-family: "Arial", sans-serif;
  width: 100%;
  height: 42px;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 14px;
}

a#send-it {
  width: 35px;
  font-weight: 700;
  padding: 10px 10px 0;
  background: #eee;
  border-radius: 1px;
}
a#send-it svg {
  fill: #a6a6a6;
  height: 22px;
  width: 15px;
}

.first-msg {
  background: transparent;
  padding: 30px;
  text-align: center;
}
.first-msg span {
  background: #e2e2e2;
  color: #333;
  font-size: 14.2px;
  line-height: 1.7;
  border-radius: 10px;
  padding: 15px 20px;
  display: inline-block;
}

.start-chat .blanter-msg {
  display: flex;
}

#get-number {
  display: none;
}

a.close-chat {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #fff;
  font-size: 30px;
}

@keyframes ZpjSY {
  0% {
    background-color: #b6b5ba;
  }
  15% {
    background-color: #111111;
  }
  25% {
    background-color: #b6b5ba;
  }
}
@keyframes hPhMsj {
  15% {
    background-color: #b6b5ba;
  }
  25% {
    background-color: #111111;
  }
  35% {
    background-color: #b6b5ba;
  }
}
@keyframes iUMejp {
  25% {
    background-color: #b6b5ba;
  }
  35% {
    background-color: #111111;
  }
  45% {
    background-color: #b6b5ba;
  }
}
@keyframes showhide {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes showchat {
  from {
    transform: scale(0);
    opacity: 0;
  }
}
@media screen and (max-width: 480px) {
  #whatsapp-chat {
    width: auto;
    left: 5%;
    right: 5%;
    font-size: 80%;
  }
  .about .ftbox ul{
    display: block ;
  }
  .about .ftbox ul li.space{
    margin: 15px 0px;
  }
}
.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}

.whatsapp-message-container {
  display: flex;
  z-index: 1;
}

.whatsapp-message {
  padding: 7px 14px 6px;
  background-color: white;
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 0;
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: -54px;
  max-width: calc(100% - 66px);
}

.whatsapp-chat-body {
  padding: 20px 20px 20px 10px;
  background-color: #e6ddd4;
  position: relative;
}
.whatsapp-chat-body::before {
  display: block;
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.08;
  background-image: url("https://elfsight.com/assets/chats/patterns/whatsapp.png");
}

.dAbFpq {
  display: flex;
  z-index: 1;
}

.eJJEeC {
  background-color: white;
  width: 52.5px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.1s ease 0s;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
}

.hFENyl {
  position: relative;
  display: flex;
}

.ixsrax {
  height: 5px;
  width: 5px;
  margin: 0px 2px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 0px;
  background-color: #9e9da2;
  animation-name: ZpjSY;
}

.dRvxoz {
  height: 5px;
  width: 5px;
  margin: 0px 2px;
  background-color: #b6b5ba;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 0px;
  animation-name: hPhMsj;
}

.kAZgZq {
  padding: 7px 14px 6px;
  background-color: white;
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: -54px;
  max-width: calc(100% - 66px);
}
.kAZgZq::before {
  position: absolute;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  top: 0px;
  left: -12px;
  width: 12px;
  height: 19px;
}

.bMIBDo {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
}

.iSpIQi {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: #111111;
}

.iSpIQi {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: #111111;
}

.cqCDVm {
  text-align: right;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  margin-right: -8px;
  margin-bottom: -4px;
}

.combo_main {
  background: linear-gradient(to left, #17c0e9, #c96ddd, #f45162);
  position: relative;
  z-index: 2;
  padding: 75px 60px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.combo_main::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(http://rainbowit.net/html/trydo/assets/images/pattern-1.png);
  content: "";
  z-index: -1;
  border-radius: 20px;
  opacity: 0.5;
}
.combo_main h1 {
  color: #ffffff;
  font-size: 33px;
  font-weight: 900;
  line-height: 42px;
  text-align: center;
}
.combo_main .combo_pricing
{
  background: white;
  position: relative;
    border-radius: 10px;
    padding: 10px;
    padding-left: 45px;
    margin-bottom: 15px;

}
.combo_main .combo_pricing .combo_pricing_sign {
  position: absolute;
  left: 20px;
  top: 5px;
  color: #0f0f1e;
  font-size: 27px;
}
.combo_ul li{
  display: inline-block;
  vertical-align: top;
  width: 33%;
}
.combo_ul li .packaging-lists > ul >li {
  display: block;
  margin: 0;
  width: 100%;
  vertical-align: top;
  line-height: 24px;
  position: relative;
}
.packaging-lists ul li {
  color: #ffffff;
  font-size: 14px;
  padding-left: 25px;
  font-family: 'Poppins', sans-serif;
}
.packaging-lists ul li i{
  font-size: 12px;
}
.packaging-lists {
  float: left;
  width: 80%;
}
.combo_ul  li .packaging-lists > ul >li.heading {
  background: #ffffff;
  color: #1a9dbb;
  color: #380083;
  rder: none;
  font-weight: 700;
  font-size: 17px;
  margin: 0 0 10px;
  display: block;
  text-align: center;
  border-radius: 3px;
  padding: 9px 2px;
  line-height: 24px;
  position: relative;
  width: 100%;
  vertical-align: top;
}
.packaging-lists .price{
  font-size: 34px;
    font-weight: 800;
}
.btn_order{
  min-width: 220px;
  text-align: center;
  padding: 15px 30px;
  font-weight: 700;
  background: black;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  margin-top: 32px;
}
.btn_order:hover{
  box-shadow: 0 0 8px 2px black;
}
.btn_disc{
  min-width: 220px;
  text-align: center;
  color: black;
  padding: 15px 30px;
  font-weight: 700;
  background: white;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  margin-top: 18px;
}
.btn_disc:hover{
  background: transparent;
color: white;

}
/*--------------------------
Call To Action Wrapper 
-----------------------------*/
.call-to-action {
  background: linear-gradient(to left, #17c0e9, #c96ddd, #f45162);
  position: relative;
  z-index: 2;
  padding: 75px 0px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.call-to-action::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(http://rainbowit.net/html/trydo/assets/images/pattern-1.png);
  content: "";
  z-index: -1;
  border-radius: 20px;
  opacity: 0.5;
}
.call-to-action .inner > span {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 9px;
}
.call-to-action .inner h2 {
  color: #ffffff;
  font-size: 33px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  line-height: 42px;
  float: left;
}
@media only screen and (max-width: 900px) {
  .call-to-action .inner h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .combo_ul li{
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
  .mt-md-45{
    margin-top: 45px;
  }
  .packaging-lists{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .combo_ul li .packaging-lists > ul >li{
    justify-content: center;
  }
  .packaging-lists .price{
    text-align: center;
  }
  .btn_order{
    min-width: 100%;
  }
  .btn_disc{
    min-width: 100%;
  }
  .combo_main h1{
    font-size: 23px;
    font-weight: 700;
    line-height: 33px;
  }
}
.call-to-action .inner a.rn-button-style--2 {
  margin-top: 30px;
  background: transparent;
}
.call-to-action .inner a.rn-button-style--2:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #f9004d;
}

.butn-cust{
  background-color: #000;
  color: white !important;
  border: 2px solid black;
  transition: all 0.3s ease-in-out;
}
.butn-cust:hover{
  box-shadow: 0 0 8px 2px black;
  
}

.floatbutton {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  position: fixed;
  right: -370px;
  top: 5%;
  font-size: 0;
  width: 420px; /* transform: translateY(-50%); */
  z-index: 9;
}
.floatbutton .clickbutton {
  width: 50px;
  z-index: 9;
  height: 200px;
  cursor: pointer;
  box-shadow: -20px 7px 18px -7px rgba(87, 184, 151, 0.09);
  border-radius: 10px 0px 0px 10px;
  display: inline-block;
  padding-top: 0;
  vertical-align: top;
  margin-top: 100px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  background: #17c0e9;
  position: relative;

}
.floatbutton .clickbutton .crossplus:before {
  content: "";
  display: none;
  position: absolute;
  width: 20px;
  height: 2px;
  right: 0;
  background: #fbb334;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.floatbutton .clickbutton .crossplus:after {
  content: "";
  display: none;
  position: absolute;
  width: 2px;
  height: 20px;
  right: 0;
  background: #fab334;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.floatbutton .clickbutton .crossplus {
  position: absolute;
  display: block;
  transform: rotate(-90deg);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  left: -54px;
  white-space: pre;
  bottom: 87px;
}

.arrow_img{
  width: 200px;
  position: absolute;
  right: 10%;
  top: 30%;
  transform: rotate(30deg) scaleX(-1) translateY(-80%);
}

.arrow_img_alt{
  width: 200px;
  position: absolute;
  left: 10%;
  top: 30%;
  transform: rotate(330deg)  translateY(-80%);
}

.imgwrp{
  margin-top: 30px;
}

 .imgwrp ul li:nth-child(6) img{
  width: 60px;
}
.visa_img{
  width: 400px;
  margin: auto;
}

.imgwrp ul {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgwrp ul li {
  list-style-type: none;
  display: inline-block;
  align-items: center;
}

.imgwrp ul li img {
  margin: 0 10px;
  width: 125px;
}
.agency .content h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.agency .content ul {
  margin: 0;
}

.agency .content ul li {
  margin-top: 25px;
  padding-left: 25px;
  position: relative;
}

.agency .content ul li:after {
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #999;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
}

.agency .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.agency .img .imgone {
  width: 55%;
  position: relative;
  bottom: -80px;
  margin-bottom: 80px;
  border-radius: 4px;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.agency .img .imgtwo {
  width: 55%;
  position: absolute;
  right: 0;
  border-radius: 4px;
  border: 5px solid #fff;
  -webkit-box-shadow: -15px 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: -15px 10px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  z-index: 2;
}

.agency .img .icon {
  width: 100px;
  height: 100px;
  line-height: 135px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  bottom: 20%;
  left: 40%;
  z-index: 3;
}

.agency .img .icon:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: -1;
}

.agency .img .icon svg {
  fill: #12c2e9;
  width: 90px;
}

.agency .img .exp {
  position: absolute;
  right: 0;
  bottom: 0;
}

.agency .img .exp h6 {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  position: relative;
}

.agency .img .exp h6:after {
  content: '';
  width: 30px;
  height: 1px;
  background: #12c2e9;
  position: absolute;
  left: -40px;
  top: 45%;
}

.agency .img .exp h2 {
  font-size: 70px;
  font-weight: 700;
}

.agency .imgsec {
  padding-right: 30px;
}

.agency .imgsec .imgone {
  position: relative;
  top: 0px;
}

.agency .imgsec .imgtwo {
  position: relative;
  bottom: 0px;
}

.agency .imgsec .exp {
  padding: 10px 30px;
  position: relative;
  margin-top: 15px;
}

.agency .imgsec .exp:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
}

.agency .imgsec .exp:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: -webkit-gradient(linear, left top, right top, from(#12c2e9), color-stop(#c471ed), to(#f64f59));
  /* background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59); */
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  z-index: -2;
}

.agency .imgsec .exp h6 {
  color: #999;
  margin-top: 5px;
}

.agency .imgsec .exp h2 {
  font-size: 70px;
  font-weight: 700;
}

/* ====================== [ Start Responsive ] ====================== */
@media screen and (max-width: 1460px) {
  .arrow_img_1{
    width: 105px !important;
  }
}
@media screen and (max-width: 1360px) {
  .custom_text{
    right: 20% !important;
  }
  .custom_text h3{
    font-size: 16px !important;
  }
  .arrow_img_1{
    width: 100px !important;
    right: 30% !important;
    top: 0;
    transform: rotate(30deg) scaleX(-1) translateY(-80%) !important;
  }
  .arrow_img {
    width: 130px !important;
    position: absolute;
    right: 6%;
    top: 25%;
    transform: rotate(30deg) scaleX(-1) translateY(-120%);
}
.arrow_img_alt{
  width: 130px !important;
  top: 25%;
  left: 6%;
  transform: rotate(330deg) translateY(-120%);
}
}
@media screen and (max-width: 1266px) {
  .navbar .navbar-nav .nav-link{
    font-size: 11px;
  }
}
@media screen and (max-width: 1200px) {
  .navbar .navbar-nav .nav-link {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    padding: 10px 8px;
    margin: 10px 2px;
}
.navbar .container-fluid {
  width: 98%;
}
.navbar .logo{
  width: 160px;
}
}
@media screen and (max-width: 991px) {
  .imgwrp ul li img{
    width: 90px;
  }
  .imgwrp ul li:nth-child(6) img{
    width: 40px;
  }
  .inner {
    text-align: center;
  }
  footer.sub-bg{
    padding-top: 200px;
  }
  .cbh-phone{
    position: absolute;
    top: 40px;
    left: -45px;
  }
  .mail_main{
    position: absolute;
    right: auto;
    left: 100px;
    top: 90px;
  }
  .bg-img {
    background-position: center !important;
  }
  .md-mb10 {
    margin-bottom: 10px;
  }
  .md-mb20 {
    margin-bottom: 20px;
  }
  .md-mb30 {
    margin-bottom: 30px;
  }
  .md-mb40 {
    margin-bottom: 40px;
  }
  .md-mb50 {
    margin-bottom: 50px;
  }
  .md-mb60 {
    margin-bottom: 60px;
  }
  .md-mb70 {
    margin-bottom: 70px;
  }
  .md-mb80 {
    margin-bottom: 80px;
  }
  .md-pb10 {
    padding-bottom: 10px;
  }
  .md-pb20 {
    padding-bottom: 20px;
  }
  .md-pb30 {
    padding-bottom: 30px;
  }
  .md-pb40 {
    padding-bottom: 40px;
  }
  .md-pb50 {
    padding-bottom: 50px;
  }
  .md-pb60 {
    padding-bottom: 60px;
  }
  .md-pb70 {
    padding-bottom: 70px;
  }
  .md-pb80 {
    padding-bottom: 80px;
  }
  .order2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .container {
    max-width: 720px;
  }
  .navbar .icon-bar {
    color: #fff;
  }
  .navbar {
    min-height: 60px;
  }
  .navbar .navbar-collapse {
    max-height: 340px;
    overflow: auto;
    background: #11141b;
    text-align: center;
    padding: 10px 15px;
  }
  .navbar .nav-link {
    margin: 10px auto !important;
  }
  .navbar .logo {
    margin-left: 15px;
    width: 180px;
  }
  .nav-scroll .icon-bar,
  .light .icon-bar {
    color: #111;
  }
  .nav-scroll .navbar-collapse .nav-link,
  .light .navbar-collapse .nav-link {
    color: #fff !important;
  }
  .nav-scroll .navbar-collapse .active,
  .light .navbar-collapse .active {
    color: #12c2e9 !important;
  }
  .navbar .search {
    border: 0;
    padding: 0;
    margin-top: 30px;
  }
  .navbar .search .search-form {
    display: block;
    position: static;
    height: auto;
  }
  .navbar .search .search-form form {
    position: static;
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .navbar .search .search-form form input {
    padding: 15px;
    width: 100%;
    font-size: 14px;
  }
  .navbar .search .icon,
  .navbar .search .search-form .close {
    display: none;
  }
  .navbar-nav .dropdown-menu {
    opacity: 1;
    visibility: visible;
    display: none;
    background: #0c0f16;
    text-align: center;
    color: #eee;
  }
  .navbar-nav .dropdown-menu .dropdown-item {
    color: #eee;
  }
  .navbar-nav .dropdown-menu.show {
    display: block;
  }
  .navbar .navbar-nav .nav-link {
    padding: 0;
  }
  .navbar .navbar-nav .nav-link:hover {
    color: #12c2e9;
  }
  .slider .parallax-slider .caption.dig {
    text-align: center;
  }
  .slider .parallax-slider .caption.dig h1 {
    font-size: 60px;
  }
  .slider .parallax-slider .caption.dig h1 .tline {
    margin-left: 0;
  }
  .slider .parallax-slider .caption h1 {
    font-size: 50px;
    letter-spacing: 1px;
  }
  .slider .parallax-slider .caption h1 br {
    display: none;
  }
  .slider-stwo .img {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .slider-stwo:after {
    display: none;
  }
  .freelancre .img {
    margin-bottom: 50px;
  }
  .freelancre .states {
    position: relative;
    margin-top: 80px;
  }
  .freelancre .states ul.flex {
    display: block;
  }
  .freelancre .states ul.flex li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 50px;
  }
  .freelancre .states ul.flex .mail-us {
    float: right;
    margin-bottom: 0;
  }
  .showcase-full .parallax-slider .caption h1 span {
    margin-left: 0;
  }
  .showcase-carus .caption h1,
  .showcase-carus .copy-cap h1 {
    font-size: 60px;
    left: auto;
    margin-left: 20px;
  }
  .circle-slide .swiper-slide .bg-img {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    height: 380px !important;
    width: 380px !important;
    margin: auto !important;
  }
  .circle-slide .swiper-slide.swiper-slide-active .bg-img {
    opacity: 1;
    visibility: visible;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 65px;
  }
  .pages-header .cont h1 {
    font-size: 35px;
  }
  .works-header .capt h1 {
    font-size: 45px;
  }
  .sec-head h3 br {
    display: none;
  }
  .agency .imgsec {
    padding-right: 0;
  }
  .about-cr .img img {
    width: 100%;
    margin: 0;
  }
  .about-cr .cont h3 {
    margin-left: 0;
  }
  .about-cr .cont {
    padding-bottom: 120px;
  }
  .testimonials .item {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .testimonials .container-fluid {
    width: auto;
    margin-left: 0;
  }
  #circle {
    display: none;
  }
  .block-sec .vid-area .cont,
  .about .content,
  .skills-box,
  .about-ar .img .hero-bg {
    margin-bottom: 50px;
  }
  .min-area .content,
  .min-area .img {
    padding: 120px 0;
  }
  .services .rtwo > div:nth-of-type(2),
  .services .rone > div:nth-of-type(2) {
    margin-bottom: 0px;
  }
  .services .step-item.xbottom,
  .services .step-item.xtop {
    bottom: auto;
    top: auto;
  }
  .services .step-item {
    margin: 25px 0;
  }
  .block-sec .testim-box {
    margin-bottom: 120px;
  }
  .block-sec .vid-area {
    display: none;
  }
  .portfolio .filtering {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
  }
  .portfolio-frl .gallery .items,
  .portfolio .gallery .items {
    padding: 0 10px;
    margin: 10px 0;
  }
  .case-study .controls {
    top: 100px;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
  }
  .slider .parallax-slider .caption p {
    margin-left: 0;
  }
  .arch-slider .parallax-slider .explore {
    margin-left: 0;
    margin-top: 50px;
  }
  .blog-grid .item.active {
    margin: 50px 0;
  }
  .footer-half:after {
    width: 100%;
  }
  .footer-half .cont {
    margin-bottom: 50px;
  }
  .next-prog .box .caption h1,
  .next-prog .box .copy-cap h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .imgwrp ul{
    display: block;
    text-align: center;
  }
  .imgwrp ul li{
   width: 100%;
   margin: 10px 0px;
  }
  .imgwrp ul li img{
    width: 90px;
  }
  .imgwrp ul li:nth-child(6) img{
    width: 40px;
  }
  .arrow_img_alt{
    width: 100px !important;
  }
  .arrow_img {
    width: 100px !important;
}
  .custom_text{
    right: 0 !important;
  }
  .custom_text h3{
    font-size: 16px !important;
  }
  .arrow_img_1{
    width: 100px !important;
    right: 20% !important;
    top: 0;
    transform: rotate(30deg) scaleX(-1) translateY(-80%) !important;
  }
  .sm-mb10 {
    margin-bottom: 10px;
  }
  .sm-mb20 {
    margin-bottom: 20px;
  }
  .sm-mb30 {
    margin-bottom: 30px;
  }
  .sm-mb40 {
    margin-bottom: 40px;
  }
  .sm-mb50 {
    margin-bottom: 50px;
  }
  .sm-mb60 {
    margin-bottom: 60px;
  }
  .sm-mb70 {
    margin-bottom: 70px;
  }
  .sm-mb80 {
    margin-bottom: 80px;
  }
  .sm-pb10 {
    padding-bottom: 10px;
  }
  .sm-pb20 {
    padding-bottom: 20px;
  }
  .sm-pb30 {
    padding-bottom: 30px;
  }
  .sm-pb40 {
    padding-bottom: 40px;
  }
  .sm-pb50 {
    padding-bottom: 50px;
  }
  .sm-pb60 {
    padding-bottom: 60px;
  }
  .sm-pb70 {
    padding-bottom: 70px;
  }
  .sm-pb80 {
    padding-bottom: 80px;
  }
  .container {
    max-width: 540px;
  }
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  .progress-wrap {
    right: 15px;
    bottom: 15px;
  }
  .navbar .logo {
    width: 140px;
  }
  .hamenu .menu-links .main-menu > li {
    font-size: 24px;
  }
  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 18px;
  }
  .topnav .container-fluid {
    padding: 0 30px;
  }
  .topnav .logo {
    width: 40px;
  }
  .btn-curve {
    padding: 8px 15px;
  }
  .btn-curve span {
    font-size: 15px;
    letter-spacing: 1px;
  }
  .slider .parallax-slider .caption h1 {
    font-size: 40px;
  }
  .slider .parallax-slider .caption h1 br {
    display: none;
  }
  .slider .parallax-slider .swiper-slide-active .caption h1 {
    font-size: 30px !important;
  }
  .slider .social-icon {
    display: none;
  }
  .slider .setone.setwo .prev-ctrl {
    left: 15px;
    bottom: 15px;
    top: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .slider .setone.setwo .next-ctrl {
    left: 70px;
    bottom: 15px;
    top: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .slider .swiper-pagination-fraction.top.botm {
    right: 15px;
    bottom: 15px;
  }
  .slider .swiper-pagination-fraction.top .swiper-pagination-current {
    font-size: 40px;
  }
  .slider .parallax-slider .caption.dig h1 {
    font-size: 40px;
  }
  .particles .cont h1 {
    font-size: 55px;
  }
  .slider-stwo ul li div {
    display: block;
  }
  .slider-stwo ul li .cont {
    display: block;
    vertical-align: auto;
    padding-left: 0;
    margin-top: 15px;
  }
  .freelancre {
    padding-top: 200px;
    padding-bottom: 0;
    text-align: center;
  }
  .freelancre .img {
    margin-left: auto;
    margin-right: auto;
  }
  .freelancre:after,
  .freelancre .states ul.flex .mail-us {
    display: none;
  }
  .showcase-carus.circle-slide .caption {
    left: 0;
    text-align: center;
  }
  .showcase-carus.circle-slide .copy-cap {
    display: none;
  }
  .showcase-carus .swiper-slide .bg-img {
    margin: 0;
    height: 100vh;
    background-position: center center;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 50px;
    min-width: auto;
    margin: auto !important;
    left: 0;
  }
  .showcase-full .parallax-slider .caption .discover {
    width: 120px;
    height: 120px;
    letter-spacing: 2px;
    text-transform: capitalize;
  }
  .pages-header {
    padding: 120px 0 80px;
    overflow-x: hidden;
  }
  .works-header .capt h2 {
    font-size: 40px;
  }
  .slider.fixed-slider,
  .works-header.hfixd {
    position: static;
  }
  .main-content {
    margin-top: 0 !important;
  }
  .sub-title h6 {
    font-size: 12px;
  }
  .sec-head h3 {
    font-size: 30px;
  }
  .sec-head h6 {
    font-size: 15px;
    letter-spacing: 5px;
  }
  .agency .imgsec .exp {
    margin-bottom: 15px;
  }
  .about .img-mons .img1, .about .img-mons .img2, .about .img-mons .img3 {
    height: auto;
  }
  .about .img-mons .img1, .about .img-mons .img2, .about .img-mons .img3 {
    margin-bottom: 10px;
  }
  .about .img-mons .img3 {
    width: 100%;
    margin-top: 0;
  }
  .about-us .img .stauts .item {
    padding: 25px 15px;
    margin-right: 20px;
  }
  .about-us .img .stauts .item:last-of-type {
    margin-right: 0;
  }
  .about-us .img .stauts .item h4 {
    font-size: 30px;
  }
  .about-us .img .stauts .item h4 span {
    font-size: 20px;
  }
  .about-us .img {
    padding: 0;
  }
  .about-cr .cont h3 {
    font-size: 40px;
  }
  .services .step-item {
    padding: 60px 40px;
  }
  .testimonials .item p {
    font-size: 17px;
  }
  .skills-circle {
    padding-bottom: 0 !important;
  }
  .skills-circle .item {
    margin-bottom: 50px;
  }
  .team .navs {
    top: 20px;
  }
  .block-sec .vid-area .cont h3 {
    font-size: 34px;
  }
  .block-sec .testim-box:after {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
  }
  .call-action .content h2 {
    font-size: 7vw;
  }
  .portfolio .filtering .filter {
    padding: 15px 5px;
    border-radius: 10px;
  }
  .portfolio .filtering span {
    margin: 5px 10px;
  }
  .portfolio .filtering span:after {
    display: none;
  }
  .portfolio-cr .items {
    width: 100% !important;
  }
  .team-crv .img-box .img.sizxl,
  .team-crv .img-box .img.sizmd,
  .team-crv .img-box .img.sizsm {
    width: 100%;
  }
  .blog-pg.blog-list .item .cont {
    padding-left: 15px;
  }
  .blog-pg.single .post .author .author-img,
  .blog-pg.single .post .author .info,
  .blog-pg.single .comments-area .item .comment-img,
  .blog-pg.single .comments-area .item .info {
    display: block;
  }
  .blog-pg.single .post .author .info,
  .blog-pg.single .comments-area .item .info {
    padding-left: 0;
    margin-top: 15px;
  }
  .intro-section .htit {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
 
  .call-to-action .inner h2{
    font-size: 20px;
    line-height: 28px;
  }
  .arrow_img_alt{
    top: -2%;
    left: 16%;
    width: 90px;
  }
  .arrow_img{
    top: -2%;
    right: 16%;
    width: 90px;
  }
  .custom_text{
    right: 0 !important;
  }
  .custom_text h3{
    font-size: 12px !important;
  }
  .arrow_img_1{
    width: 80px !important;
    right: 14% !important;
    top: 0;
    transform: rotate(30deg) scaleX(-1) translateY(-80%) !important;
  }
  .hamenu .cont-info {
    display: none;
  }
  .hamenu .menu-links .main-menu > li {
    font-size: 20px;
    padding: 7px;
  }
  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 16px;
  }
  .topnav .container-fluid {
    padding: 0 15px;
  }
  .slider-bus .caption h1 {
    font-size: 50px;
  }
  .showcase-full .dots {
    display: none;
  }
  .slider .txt-botm .swiper-nav-ctrl.swiper-button-next {
    right: 20px;
  }
  .slider .txt-botm .swiper-nav-ctrl.swiper-button-prev {
    left: 20px;
  }
  .pages-header .cont .path {
    font-size: 15px;
  }
  .pages-header .cont .path span {
    margin: 0 10px;
  }
  .pages-header .capt h1 {
    font-size: 24px;
  }
  .page-header .cont h1 {
    font-size: 40px;
  }
  .slider .swiper-pagination-fraction.steps {
    bottom: 60px;
  }
  .particles .cont h1 {
    font-size: 30px;
    font-weight: 700;
  }
  .circle-slide .swiper-slide .bg-img {
    height: 280px !important;
    width: 280px !important;
  }
  .freelancre .cont h1 {
    font-size: 22px;
  }
  .freelancre .img {
    width: 200px;
    height: 200px;
  }
  .showcase-carus.circle-slide .caption h1,
  .showcase-carus.circle-slide .copy-cap h1 {
    font-size: 40px;
  }
  .works-header .capt h2 {
    font-size: 30px;
    letter-spacing: 1px;
  }
  .pages-header .cont h1 {
    font-size: 20px;
  }
  .sec-head h3 {
    font-size: 20px;
  }
  .sec-head h2 {
    font-size: 55px;
  }
  .about-us .img .stauts {
    display: none;
  }
  .about-ar .img .hero-bg {
    width: 300px;
    height: 300px;
  }
  .about-ar .img .exp {
    width: 240px;
    height: 240px;
  }
  .about-ar .img .exp h2 {
    font-size: 100px;
  }
  .about-ar .content h3 {
    font-size: 35px;
  }
  .services.lficon .item-box {
    display: block;
  }
  .services.lficon .item-box .cont {
    padding-left: 0;
  }
  .min-area .content h4,
  .agency .content h4 {
    font-size: 28px;
  }
  .portfolio .gallery .items {
    padding: 0 15px;
    margin: 0 0 50px;
  }
  .portfolio .gallery .items.lg-mr {
    margin-top: 50px;
  }
  .portfolio .filtering.smplx span {
    margin: 5px 15px;
  }
  .case-study .swiper-slide .cont h4 {
    font-size: 25px;
  }
  .case-study .controls {
    top: 30px;
    right: 30px;
  }
  .case-study .controls .swiper-button-next, .case-study .controls .swiper-button-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .block-sec .vid-area .cont h3 {
    font-size: 28px;
  }
  .video .container .stauts {
    display: none;
  }
  .testimonials .item {
    display: block;
    padding: 60px 30px;
  }
  .testimonials .item p {
    margin-left: 0;
    padding-left: 0;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .testimonials .item .info {
    min-width: auto;
    margin: auto;
    margin-bottom: 30px;
    display: block;
    text-align: center;
  }
  .testimonials .item .info .img {
    margin: auto;
  }
  .blog-crv .controls {
    display: none;
  }
  .blog-crv .item .content {
    padding: 30px 15px;
  }
  .blog-list .item {
    display: block;
  }
  .blog-list .item .img {
    width: 100%;
  }
  .blog-list .item .cont {
    padding-left: 0;
    margin-top: 30px;
  }
  .blog-pg.blog-list .item .cont {
    margin-top: 0;
  }
  .blog .item h5 {
    font-size: 20px;
  }
  .blog-pg.single .post .content .share-info {
    display: block;
  }
  .blog-pg.single .post .content .share-info .social,
  .blog-pg.single .post .content .share-info .tags {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
  }
  .blog-pg.single .post .content .share-info .tags {
    margin-top: 5px;
  }
  .blog-pg.single .comments-area .item.relped {
    margin-left: 30px;
  }
  .next-prog .box .bg-img {
    width: 240px;
    height: 240px;
  }
  .next-prog .box .caption h1,
  .next-prog .box .copy-cap h1 {
    font-size: 20px;
    font-weight: 600;
  }
  .next-prog .box .caption h1 .stroke,
  .next-prog .box .copy-cap h1 .stroke {
    display: inline-block;
    color: #fff;
    -webkit-text-stroke: 0px #fff;
  }
}

/* ====================== [ End Responsive ] ====================== */

.star_img{
  width: 90px;
}




